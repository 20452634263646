@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Brandon';
  src: url('../@fonts/webFonts/BrandonRegular/Brandon-Regular.otf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Brandon';
  src: url('../@fonts/webFonts/BrandonBold/Brandon_bold.otf');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Brandon';
  src: url('../@fonts/webFonts/BrandonLight/Brandon_light.woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Brandon';
  src: url('../@fonts/webFonts/BrandonMedium/Brandon_med.otf');
  font-weight: 500;
  font-style: normal;
}

body {
  font-family: 'Brandon', sans-serif;
}

.sectionHeader {
  background: #fff;
}

.w-inherit {
  width: inherit;
}

.carousel.carousel-slider {
  padding-bottom: 64px;
}

.carousel .control-dots {
  margin-bottom: 20px !important;
}

.carousel .control-dots .dot {
  background: #32ADBB !important;
  opacity: 0.3 !important; 
  box-shadow: none !important;
  margin: 4px !important;
}

.carousel .control-dots .dot.selected,
.carousel .control-dots .dot:hover {
  background: #32ADBB !important;
  opacity: 1.0 !important; 
}

.siteNavSubmenu {
  list-style: none;
  padding: 0;
  margin: 0;
}

@media only screen and (min-width: 1280px) {
  .siteNavSubmenu {
    text-align: center;
  }
}

.siteNavSubmenu > li {
  padding: 10px 15px;
  font-size: 16px;
}

@media only screen and (min-width: 1280px) {
  .siteNavSubmenu > li {
    display: inline-block;
    padding: 5px 6px;
  }
}

@media only screen and (min-width: 1280px) {
  .siteNavSubmenu > li {
    padding: 5px 13px;
  }
}

.siteNavSubmenu > li > a {
  font-size: 15px;
  display: block;
  color: #fff;
  text-decoration: none;
  background-color: transparent;
}

@media only screen and (min-width: 1280px) {
  .siteNavSubmenu > li > a {
    text-align: center;
  }
}

@media only screen and (max-width: 1280px) {
  .siteNavSubmenu > li > a {
    font-size: 13px;
  }
}

.siteNavLink {
  font-size: 15px;
  display: block;
  color: #fff;
  text-transform: uppercase;
  text-decoration: none;
}

.siteNavLink:hover {
  color: #fff;
}

@media only screen and (min-width: 1280px) {
  .siteNavLink {
    text-align: center;
  }
}

.container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 20px;
  padding-right: 20px;
  position: relative;
}

.container:before,
.container:after {
  content: ' ';
  display: table;
}

.container:after {
  clear: both;
}

@media (min-width: 1280px) {
  .container {
    width: 750px;
  }
}

@media (min-width: 1280px) {
  .container {
    width: 970px;
  }
}

@media (min-width: 1280px) {
  .container {
    width: 1170px;
  }
}

.tabPane {
  display: block;
}

@media screen and (max-width: 1279px) {
  .tabPane {
    display: none;
  }
}

.tabContent {
  display: block;
  background: #ff6900;
}

@media only screen and (min-width: 1280px) {
  .tabContent {
    display: block !important;
  }
}

.navTabs {
  border-color: #ff6900;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  border-bottom: 1px solid #dddddd;
}

@media only screen and (min-width: 1280px) {
  .navTabs {
    left: 170px;
    position: absolute;
    top: -52px;
  }
}

@media only screen and (min-width: 1280px) {
  .navTabs {
    left: 200px;
  }
}

@media only screen and (min-width: 1500px) {
  .navTabs {
    left: 100px;
  }
}

.iconAlignment {
  margin-top: -0.35rem;
}

.roundedFull {
  border-radius: 9999px !important;
  width: 6rem !important;
  height: 6rem !important;
}

.disabledButton:disabled {
  --tw-bg-opacity: 0.5 !important;
  background-color: rgb(255 105 0 / var(--tw-bg-opacity)) !important;
}

.activeNavTab {
  background: #ff6900;
  width: 50%;
  cursor: pointer;
  float: left;
  margin-bottom: -1px;
  position: relative;
  display: block;
}

.activeNavTab > a {
  border-radius: 0;
  background: #e0e0e0;
  color: #a3a3a3;
  border: 0;
  display: inline;
  padding: 10px 15px;
  float: left;
  width: 48px;
  text-align: center;
  font-size: 20px;
  border-radius: 0;
  margin: 0;
  font-weight: 700;
}

@media only screen and (min-width: 1280px) {
  .siteTabLink {
    display: none;
  }
}

@media only screen and (min-width: 1280px) {
  .activeNavTab > a {
    background: #ff6900;
    font-size: 24px;
    width: 100%;
  }
}

@media only screen and (min-width: 1280px) {
  .activeNavTab > a {
    padding: 9px 25px;
  }
}

@media only screen and (min-width: 1280px) {
  .activeNavTab > a {
    padding: 9px 35px;
  }
}

.visibleXs {
  display: none !important;
}
@media (max-width: 1279px) {
  .visibleXs {
    display: inline-block !important;
  }
}

@media (max-width: 1279px) {
  .hiddenXs {
    display: none !important;
  }
}

.contactNumber {
  display: inline-flex;
  justify-content: flex-end;
  margin-bottom: 8px;
}

.contactNumber span {
  font-size: 13px;
  float: left;
  color: #333;
  padding: 0 2px;
  font-weight: bolder;
}

.contactNumber span a {
  color: #333;
  text-decoration: none;
}

.services {
  display: inline-flex;
  justify-content: flex-end;
}

.services span {
  font-size: 13px;
  float: left;
  color: #333;
  padding: 0 2px;
}

.services span a {
  color: #333;
  text-decoration: none;
}

.headerMeta {
  position: absolute;
  top: 10px;
  right: 0;
  display: flex;
  flex-direction: column;
}

@media (max-width: 1279px) {
  .headerMeta {
    display: none !important;
  }
}

.logoColumn {
  flex: 0 0 30%;
}

@media only screen and (min-width: 1280px) {
  .logoColumn {
    flex: 0 0 50%;
    padding: 15px 0 5px;
  }
}

@media only screen and (min-width: 1500px) {
  .logoColumn {
    flex: 0 0 40%;
    padding: 15px 0 5px;
  }
}

.siteLogo {
  display: block;
  margin: 15px 0px 5px 20px;
  max-width: 120px;
}

@media only screen and (min-width: 1280px) {
  .siteLogo {
    margin: 8px 0 0;
  }
}

.siteLogo img {
  display: block;
  width: 100%;
}

.searchColumn {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 0 0 10%;
  text-align: right;
  margin-top: 8px;
}

@media only screen and (min-width: 1280px) {
  .searchColumn {
    justify-content: start;
  }
}

@media only screen and (min-width: 1280px) {
  .searchColumn {
    flex: 0 0 15%;
  }
}

.searchToggleButton {
  height: auto;
  border: 0;
  border-radius: 0;
  font-size: 1.2rem;
  background: transparent;
  outline: 0 !important;
  padding: 15px;
}

.mobileSearchBtn {
  border: 0;
  background: transparent;
  font-size: 1.5rem !important;
  padding: 0 10px !important;
  white-space: nowrap;
  line-height: inherit !important;
  overflow: visible;
  text-transform: none;
  color: inherit;
  font: inherit;
  margin: 0;
}

@media (max-width: 1280px) {
  .mobileSearchBtn {
    display: none !important;
  }
}

@media only screen and (min-width: 1280px) {
  .searchToggleButton {
    display: none;
  }
}

.searchToggleButton span {
  color: transparent;
}

.srOnly {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

@media only screen and (min-width: 1280px) {
  .headerSearch {
    width: 200px;
  }
}

@media only screen and (min-width: 1500px) {
  .headerSearch {
    width: 250px;
  }
}

.inputGroup {
  position: relative;
  display: table;
  border-collapse: separate;
}

.formControl {
  border: 2px solid #c6c6c6;
  border-radius: 8px 0 0 8px;
  border-right: 0;
  box-shadow: none;
  display: table-cell;
  position: relative;
  z-index: 2;
  float: left;
  width: 100%;
  margin-bottom: 0;
  -webkit-appearance: none;
  box-sizing: border-box;
  height: 36px;
  padding: 6px 12px;
  font-size: 16px;
  line-height: 1.42857;
  color: #555;
  background-color: #fff;
  background-image: none;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.inputGroupBtn {
  position: relative;
  font-size: 0;
  white-space: nowrap;
  width: 1%;
  vertical-align: middle;
  display: table-cell;
  border-collapse: separate;
  text-align: right;
}

@media only screen and (max-width: 1279px) {
  .searchWrapper {
    display: none;
  }
}

.searchDefaultBtn {
  background: transparent;
  border: 2px solid #c6c6c6;
  border-left: 0;
  border-radius: 0 8px 8px 0;
  height: 36px;
  z-index: 2;
  margin-left: -1px;
  position: relative;
  color: #0f0f0f;
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400 !important;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 16px !important;
  line-height: 1.42857 !important;
  user-select: none;
  -webkit-appearance: button;
  text-transform: none;
  overflow: visible;
  font: inherit;
  margin: 0;
}

.accountColumn {
  display: flex;
  align-items: flex-end;
  z-index: 100;
  flex: 0 0 50%;
  z-index: 100;
}

@media only screen and (min-width: 1280px) {
  .accountColumn {
    flex: 0 0 25%;
    justify-content: end;
  }
}

@media only screen and (min-width: 1280px) {
  .accountColumn {
    flex: 2 0 6%;
    justify-content: end;
  }
}

.dropdown {
  position: relative;
}

@media only screen and (min-width: 1280px) {
  .dropdown {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media only screen and (min-width: 1280px) {
  .dropdown {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.cartColumn {
  margin-left: 10px;
  flex: 0 0 90%;
  flex-shrink: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
}

@media only screen and (min-width: 1280px) {
  .cartColumn {
    flex: 0 0 10%;
  }
}

.miniCartLink {
  background: #f4f4f4;
  color: #000;
  padding: 7px 10px;
  width: max-content;
  text-align: center;
  display: flex;
  white-space: nowrap;
  text-decoration: none;
}

@media only screen and (min-width: 1280px) {
  .miniCartLink {
    text-align: left;
    width: 100vw;
    max-width: 90px;
  }
}

@media only screen and (min-width: 992px) {
  .miniCartLink {
    text-align: left;
    width: 100vw;
    max-width: max-content;
  }
}

.miniCartLink > i {
  color: #ff6900;
}

.cartCounter {
  font-size: 18px;
  padding: 0 1px 0 2px;
}

@media only screen and (min-width: 1280px) {
  .cartItemLabel {
    display: inline;
  }
}

.headerRow {
  display: flex;
  align-items: flex-end;
  transition: margin 0.5s ease;
  position: relative;
}

@media only screen and (max-width: 1279px) {
  .headerRow {
    align-items: flex-end;
  }
}

@media only screen and (min-width: 1280px) {
  .headerRow {
    align-items: flex-end;
    padding-bottom: 5px;
  }
}

.dropdownMenu {
  left: auto;
  right: 0;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  list-style: none;
  font-size: 20px;
  text-align: left;
  /* background-color: #fff; */
  border: 1px solid #cccccc;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  box-shadow: 0 6px 12px #0000002d;
  background-clip: padding-box;
  margin-top: 0;
  white-space: nowrap;
}

.dropdownMenu > li > a {
  display: inline;
  padding: 3px 20px;
  clear: both;
  font-weight: 400;
  line-height: 1.42857;
  color: #333;
  white-space: nowrap;
}

.dropdownMenu > li > a:hover,
.dropdownMenu > li > a:focus {
  text-decoration: none;
  color: #262626;
  background-color: #f5f5f5;
}

@media only screen and (min-width: 1279px) {
  .dropdownMenu {
    margin-bottom: 5px;
    list-style: none;
    padding: 0;
    /* margin: 8px 0 0; */
    display: inline-block;
    position: static;
    border: 0;
    box-shadow: none;
    min-width: 120px;
    z-index: 999;
  }
}

.loginOption {
  border-bottom: none;
  content: '' !important;
}

.loginOption:before {
  content: '';
}

@media only screen and (min-width: 1280px) {
  .loginOption:before {
    color: #e0e0e0;
  }
}

@media only screen and (min-width: 1280px) {
  .loginOption {
    display: inline-block;
    vertical-align: top;
    padding-left: 3px;
  }
}

.loginOption2 {
  border-bottom: none;
}

.loginOption2:before {
  content: '|';
}

@media only screen and (min-width: 1280px) {
  .loginOption2:before {
    content: '|';
    color: #e0e0e0;
  }
}

@media only screen and (min-width: 1280px) {
  .loginOption2 {
    display: inline-block;
    vertical-align: top;
    padding-left: 3px;
    content: '|';
  }
}

.loginItem {
  display: inline;
  padding: 3px 20px;
  clear: both;
  font-weight: 400;
  line-height: 1.42857;
  color: #333;
  white-space: nowrap;
  text-decoration: none;
  padding: 5px 20px !important;
  content: '';
}

@media only screen and (min-width: 1280px) {
  .loginItem {
    color: #000;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 700;
    display: inline;
    padding: 5px 0 !important;
  }
}

.navTabs {
  border-color: #ff6900;
  border-bottom: 1px solid #dddddd;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  margin-top: 0;
}

.navTabs:before {
  content: ' ';
  display: table;
}

@media only screen and (min-width: 1280px) {
  .navTabs {
    left: 170px;
    position: absolute;
    top: -52px;
  }
}

@media only screen and (min-width: 1280px) {
  .navTabs {
    left: 200px;
  }
}

@media only screen and (min-width: 1500px) {
  .navTabs {
    left: 180px;
  }
}

@media only screen and (min-width: 1800px) {
  .navTabs {
    left: 100px;
  }
}

@media only screen and (min-width: 2200px) {
  .navTabs {
    left: 50px;
  }
}

@media only screen and (min-width: 3000px) {
  .navTabs {
    left: 0px;
  }
}

@media only screen and (max-width: 1430px) {
  .navTabs {
    left: 240px;
  }
}

@media only screen and (max-width: 1350px) {
  .navTabs {
    left: 270px;
  }
}

.activeTab {
  background: #ff6900;
  width: 50%;
  cursor: pointer;
  float: left;
  margin-bottom: -1px;
  position: relative;
  display: block;
}

.inActiveTab {
  background: #e0e0e0;
  width: 50%;
  cursor: pointer;
  float: left;
  margin-bottom: -1px;
  position: relative;
  display: block;
}

.inActiveTab > a {
  width: 100%;
  white-space: nowrap;
  background: #e0e0e0;
  color: #a3a3a3;
  border: 0;
  display: inline;
  padding: 10px 15px;
  float: left;
  text-align: center;
  font-size: 20px;
  border-radius: 0;
  margin: 0;
  font-weight: 700;
  line-height: 1.4282;
}

@media only screen and (min-width: 1280px) {
  .inActiveTab > a {
    padding: 9px 20px;
    font-size: 24px;
    width: 100%;
    text-align: center;
  }
}

@media only screen and (min-width: 1280px) {
  .inActiveTab > a {
    padding: 9px 25px;
  }
}

@media only screen and (min-width: 1280px) {
  .inActiveTab > a {
    padding: 9px 35px;
  }
}

.activeTab > a {
  color: #fff;
  border: 0;
  background: #e0e0e0;
  display: inline;
  padding: 10px 15px;
  float: left;
  /* width: 48px; */
  text-align: center;
  font-size: 20px;
  border-radius: 0;
  margin: 0;
  font-weight: 700;
  line-height: 1.42857;
  cursor: default;
  position: relative;
  text-transform: uppercase;
}

.buyfirst {
  display: none !important;
  text-decoration: none;
  background: #d55800 !important;
  color: #fff;
  border: 0;
  padding: 10px 15px;
  float: left;
  width: 48px;
  text-align: center;
  font-size: 20px;
  border-radius: 0;
  margin: 0;
  font-weight: 700;
  z-index: 9;
}

.buyfirst > span {
  display: none;
  color: #fff;
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
}

@media (max-width: 1279px) {
  .rootNav {
    border-bottom: 8px solid #ff6900;
  }
  .buyfirst:before {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
  }
  .buyfirst {
    display: inline-block !important;
  }

  .buysecond {
    display: none !important;
  }
}

@media only screen and (min-width: 1280px) {
  .activeTab > a {
    background: #ff6900;
    padding: 9px 20px;
    font-size: 24px;
    width: 100%;
  }

  .buythird {
    display: none !important;
  }
}

@media only screen and (min-width: 1280px) {
  .activeTab > a {
    padding: 9px 25px;
  }
}

@media only screen and (min-width: 1280px) {
  .activeTab > a {
    padding: 9px 35px;
  }
}

.buythird {
  background: #ff6900 !important;
  color: #fff;
  border: 0;
  color: #a3a3a3;
  text-align: center;
  display: inline-block;
  position: absolute;
  border: none;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  font-weight: 700;
  font-size: 20px;
  margin-left: 48px;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  top: 0 !important;
  font-weight: 700;
  font-size: 20px;
  margin-left: 48px !important;
  display: inline-block;
  position: absolute !important;
}
@media only screen and (max-width: 480px) {
  .descriptionOverflow {
    overflow-y: scroll;
  }
}

input[id='checkterms'] + label {
  display: inline-block;
  width: 24px;
  height: 24px;
  border: 2px solid #ff6900;
  border-radius: 5px;
  cursor: pointer;
}
input[id='checkterms']:checked + label:after {
  position: relative;
  top: -3px;
  left: 4px;
  content: '\2714';
  font-size: 17px;
}
input[id='checkterms'] {
  display: none;
}

.phone-color-radio {
  appearance: none;
  -webkit-appearance: none;
  border-radius: 50%;  
  border: 3px solid #FFF;
}
